/** @deprecated use outshared-lib - PolicyProductIdEnum */
export enum ProductId {
    liability = 'AANS',
    car = 'AUTO',
    moped = 'BROM',
    scooter = 'SCOOTER',
    caravan = 'CVAN',
    bicycle = 'FIET',
    pet = 'DIER',
    motorcycle = 'MOTR',
    accidents = 'ONGV',
    legal = 'RB',
    travel = 'REIS',
    home = 'WOON',
    furniture = 'INBOEDEL',
    building = 'OPSTAL',
}
