/** @deprecated - this is moved to libs */
export enum InsuranceCardsEnum {
    AccidentInsurance = 'https://verzekeringskaarten.nl/inshared/Ongevallenverzekering',
    CaravanInsurance = 'https://verzekeringskaarten.nl/inshared/Caravanverzekering',
    ConditionsBicycleInsurance = 'https://verzekeringskaarten.nl/inshared/fietsverzekering',
    ConditionsCarInsuranceAllRisk = 'https://verzekeringskaarten.nl/inshared/Auto-Allrisk',
    ConditionsCarInsuranceLiability = 'https://verzekeringskaarten.nl/inshared/Auto-WA',
    ConditionsCarInsuranceLimitedCasco = 'https://verzekeringskaarten.nl/inshared/Auto-WA-Beperkt-Casco',
    ConditionsCarInsurancePassengers = 'https://verzekeringskaarten.nl/inshared/Auto-Inzittenden',
    HomeContentsInsurance = 'https://verzekeringskaarten.nl/inshared/inboedelverzekering-vw1020',
    HomeInsurance = 'https://verzekeringskaarten.nl/inshared/opstalverzekering-vw1020',
    LegalInsurance = 'https://verzekeringskaarten.nl/inshared/Rechtsbijstandverzekering',
    LiabilityInsurance = 'https://verzekeringskaarten.nl/inshared/Aansprakelijkheidsverzekering',
    MopedInsuranceAllRisk = 'https://verzekeringskaarten.nl/inshared/Brommer-Allrisk',
    MopedInsuranceLiability = 'https://verzekeringskaarten.nl/inshared/Brommer-Wa',
    MopedInsuranceLimitedCasco = 'https://verzekeringskaarten.nl/inshared/Brommer-WA-Beperkt-Casco',
    MotorInsuranceAllrisk = 'https://verzekeringskaarten.nl/inshared/Motor-Allrisk',
    MotorInsuranceLiability = 'https://verzekeringskaarten.nl/inshared/Motor-Wa',
    MotorInsurancePassengers = 'https://verzekeringskaarten.nl/inshared/Schadeverzekering-Opzittenden',
    MotorInsuranceStorage = 'https://verzekeringskaarten.nl/inshared/Motor-op-Stal',
    PetInsurance = 'https://verzekeringskaarten.nl/inshared/dierenverzekering',
    TravelInsurance = 'https://verzekeringskaarten.nl/inshared/Reisverzekering-Doorlopend',
}
