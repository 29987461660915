/** @deprecated - this is moved to libs */
export enum KeyCodeEnum {
    ArrowDown = 'ArrowDown',
    ArrowUp = 'ArrowUp',
    PageDown = 'PageDown',
    PageUp = 'PageUp',
    Home = 'Home',
    End = 'End',
    Escape = 'Escape',
    Enter = 'Enter',
}
