export enum SelectedPanelEnum {
    Close = 'close',
    InsuranceDetail = 'detail',
    InsuranceRegularDriver = 'bestuurderwijzigen',
    InsuranceEndProduct = 'verzekeringopzeggen',
    InsuranceEndDateProduct = 'verzekeringeerderbeeindigen',
    InsuranceStartDateChange = 'ingangsdatumwijzigen',
    InsuranceStartDateSet = 'ingangsdatumverzekering',
    InsuranceCoverage = 'dekkingwijzigen',
    InternationalMotorInsuranceCard = 'groenekaart',
    InternationalMotorInsuranceCardButtons = 'groenekaartknoppen',
    TransferInsurance = 'overstapservice',
}
